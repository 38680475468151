import request from '@/api/';

export default {
  getInstructorRequests() {
    return request.get('admin/users',
      {
        params: {
          awaiting_promotion_request: true,
        },
      });
  },
  getInstructorRequest(userId) {
    return request.get(`admin/users/${userId}`);
  },
  sendInstructorPromotionDecision(userId, payload) {
    return request.post(`admin/users/promotions/${userId}/decide`, {
      ...payload,
    });
  },
  getAllOrders(payload) {
    return request.get('admin/orders', {
      params: {
        skip: 0,
        limit: 50,
        ...payload,
      },
    });
  },
  getAllPayouts(payload) {
    return request.get('admin/payouts', {
      params: {
        skip: 0,
        limit: 50,
        ...payload,
      },
    });
  },
};
