<template>
  <div class="payouts-history">
    <div class="container">
      <base-heading class="dashboard-heading" bold="bold">
        {{ $t("paymentsHistory.heading") }}
      </base-heading>

      <base-table
        class="sales-history__table"
        :data="payouts"
        :columns="columns"
      >
        <template slot-scope="{ row }">
          <td>{{ row.id }}</td>
          <td>
            {{ formattedDateTime(row.sentAt) }}
          </td>
          <td>
            {{ row.email }}
          </td>
          <td>
            <base-price :price="row.value"/>
          </td>
          <td>

            <base-button
              @click="generatePayoutConfirmation(row.id)"
              class="payouts-history__table-status-button"
              type="contrasting"
            >
              Pobierz potwierdzenie wypłaty
            </base-button>
          </td>
        </template>
      </base-table>
      <div class="sales-history__pagination" v-if="userType === 'admin'">
        <button
          class="sales-history__pagination-button sales-history__pagination-button--left"
          @click="handlePagination('previous')"
          v-if="skip !== 0"
          type="button"
        >
          <img
            src="/assets/icons/arrow-right.svg"
            :alt="$t('arrow')"
            class="sales-history__pagination-button-icon"
          />
        </button>
        <button
          class="sales-history__pagination-button sales-history__pagination-button--right"
          @click="handlePagination('next')"
          type="button"
          v-if="payouts.length === limit"
        >
          <img
            src="/assets/icons/arrow-right.svg"
            :alt="$t('arrow')"
            class="sales-history__pagination-button-icon"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import admin from '@/api/services/admin';
import config from '@/config';
import { mapGetters } from 'vuex';

export default {
  name: 'PayoutsHistoryView',
  data: () => ({
    payouts: [],
    skip: 0,
    limit: 50,
  }),
  computed: {
    ...mapGetters({
      token: (state) => state.auth.token,
      userType: 'auth/userType',
    }),
    columns() {
      return ['Numer', 'Kiedy?', 'Komu?', 'Ile?', 'Działania'].map((column, index) => ({
        key: index,
        label: column,
      }));
    },
  },
  methods: {
    formattedDateTime(date) {
      return dayjs(date).format('DD/MM/YYYY HH:mm');
    },
    getPayoutClass(status) {
      switch (status) {
        case true:
          return 'sales-history__table-status--paid';
        case false:
          return 'sales-history__table-status--error';
        case null:
          return 'sales-history__table-status--no-data';
        default:
          return '';
      }
    },
    async handlePagination(direction) {
      if (direction === 'next') {
        this.skip += this.limit;
      } else {
        this.skip -= this.limit;
      }
      this.fetchData();
      window.scrollTo(0, 0);
    },
    generatePayoutConfirmation(id) {
      window.open(`${config.apiServerURL}admin/payouts/${id}?jwt=${this.$store.state.auth.token}`, '_blank').focus();
    },
    async fetchData() {
      this.$store.dispatch('ui/setPreloader', true);
      try {
        let payouts;
        if (this.userType === 'admin') {
          payouts = await admin.getAllPayouts({
            skip: this.skip,
            limit: this.limit,
          });
        }
        this.payouts = payouts.data;
      } catch (err) {
        this.$store.dispatch('notification/spawn', {
          errorMessage: err.response.data.message,
          type: 'error',
        });
      } finally {
        this.$store.dispatch('ui/setPreloader', false);
      }
    },
  },
  async created() {
    this.fetchData();
  },
};
</script>

<style lang="scss" src="./PayoutsHistory.scss"/>
