<template>
  <div class="sales-history">
    <div class="container">
      <base-heading class="dashboard-heading" bold="bold">
        {{ $t('paymentsHistory.heading') }}
      </base-heading>

      <base-table
        class="sales-history__table"
        :data="payments"
        :columns="columns"
      >
        <template slot-scope="{ row, index }">
          <td>{{ index + 1 }}.</td>
          <td>
            <course-thumbnail
              v-bind="row.course"
              :additionalDetails="`${$t('level')} ${row.course.difficulty}`"
              :slug="row.course.slug"
              v-if="row.course"
            />
          </td>
          <td>
            {{ formattedDate(row.time) }}
          </td>
          <td class="sales-history__table-email" v-if="userType === 'admin'">
            {{ row.owner != null ? row.owner.email : '' }}
          </td>
          <td class="sales-history__table-orderId">
            {{ row.payPalOrderId}}
          </td>
          <td>
            <base-price :price="row.price * ((100-row.promoRate) / 100)"
                        class="course-tile__price"/>
            <span v-if="row.promoRate > 0">(-{{ row.promoRate }}%)</span>
          </td>
          <td>
            <span
              class="sales-history__table-status"
              :class="[
                row.paymentStatus === 'waiting_for_payment'
                  ? 'sales-history__table-status--waiting'
                  : 'sales-history__table-status--paid'
              ]"
            >
              {{ getPaymentStatus(row.paymentStatus) }}
            </span>
          </td>
          <td class="sales-history__table-status">
            <base-button
              @click="getCourseInvoice(row.id)"
              class="sales-history__table-status-button"
              type="contrasting"
            >
              {{ $t('paymentsHistory.invoiceNormal') }}
            </base-button>

            <base-button
              @click="getCourseInvoice(row.id, 'commission')"
              class="sales-history__table-status-button"
            >
              {{ $t('paymentsHistory.invoiceCommission') }}
            </base-button>

            <base-button
              v-if="userType === 'admin'"
              @click="giveAccess(row.id)"
              class="sales-history__table-status-button"
              type="contrasting"
            >
              Aktywuj dostęp
            </base-button>
          </td>
        </template>
      </base-table>
      <div class="sales-history__pagination">
        <button
          class="sales-history__pagination-button sales-history__pagination-button--left"
          @click="handlePagination('previous')"
          v-if="skip !== 0"
          type="button">
          <img
            src="/assets/icons/arrow-right.svg"
            :alt="$t('arrow')"
            class="sales-history__pagination-button-icon"
          />
        </button>
        <button
          v-if="skip < total - limit"
          class="sales-history__pagination-button sales-history__pagination-button--right"
          @click="handlePagination('next')"
          type="button"
        >
          <img
            src="/assets/icons/arrow-right.svg"
            :alt="$t('arrow')"
            class="sales-history__pagination-button-icon"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';

import course from '@/api/services/course';
import admin from '@/api/services/admin';
import payment from '@/api/services/payment';

export default {
  name: 'SalaryHistoryView',
  data: () => ({
    payments: [],
    skip: 0,
    limit: 50,
    total: 0,
  }),
  computed: {
    ...mapGetters({
      userType: 'auth/userType',
    }),
    columns() {
      const columns = this.$t('paymentsHistory.columns')
        .map((column, index) => ({
          key: index,
          label: column,
        }));
      if (this.userType === 'admin') {
        const column = {
          key: 3,
          label: 'Email',
        };
        columns.splice(3, 0, column);
      }
      return columns;
    },
  },
  methods: {
    formattedDate(date) {
      return dayjs(date)
        .format('DD/MM/YYYY');
    },
    getPaymentStatus(status) {
      return status === 'waiting_for_payment'
        ? this.$t('paymentsHistory.waiting')
        : this.$t('paymentsHistory.bought');
    },
    getPayoutStatus(status) {
      switch (status) {
        case true:
          return this.$t('paymentsHistory.payout');
        case false:
          return this.$t('apiHandler.error.title');
        case null:
          return this.$t('no data');
        default:
          return '';
      }
    },
    getPayoutClass(status) {
      switch (status) {
        case true:
          return 'sales-history__table-status--paid';
        case false:
          return 'sales-history__table-status--error';
        case null:
          return 'sales-history__table-status--no-data';
        default:
          return '';
      }
    },
    async makeOrderPayment(id) {
      this.$store.dispatch('ui/setPreloader', {
        status: true,
        text: 'Please wait...',
      });

      try {
        const { data } = await payment.makePayment(id);
        window.location.href = data.link;
      } catch (err) {
        this.$store.dispatch('notification/spawn', {
          type: 'error',
          title: this.$t('checkout.errorTitle'),
          text: this.$t('checkout.errorText'),
        });

        this.$store.dispatch('ui/setPreloader', false);
        console.log(err);
      }
    },
    async getCourseInvoice(id, commission = null) {
      try {
        const { data } = await payment.getInvoice(id, commission);
        const blob = new Blob([data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = fileURL;
        link.download = 'invoice';

        document.body.append(link);

        link.click();
        link.remove();

        window.URL.revokeObjectURL(fileURL);
      } catch (err) {
        console.log(err);
        this.$store.dispatch('notification/spawn', {
          errorMessage: err.response.data.message,
          type: 'error',
        });
      }
    },
    async handlePagination(direction) {
      if (direction === 'next') {
        this.skip += this.limit;
      } else {
        this.skip -= this.limit;
      }
      this.fetchData();
      window.scrollTo(0, 0);
    },
    async giveAccess(id) {
      await payment.giveAccess(id);
      window.location.reload();
    },
    async fetchData() {
      this.$store.dispatch('ui/setPreloader', true);
      try {
        let payments;
        if (this.userType === 'admin') {
          payments = await admin.getAllOrders({
            skip: this.skip,
            limit: this.limit,
          });
        } else {
          payments = await course.getInstuctorSales({
            skip: this.skip,
            limit: this.limit,
          });
        }
        this.total = +payments.headers['x-total-count'];
        this.payments = payments.data;
      } catch (err) {
        this.$store.dispatch('notification/spawn', {
          errorMessage: err.response.data.message,
          type: 'error',
        });
      } finally {
        this.$store.dispatch('ui/setPreloader', false);
      }
    },
  },
  async created() {
    this.fetchData();
  },
};
</script>

<style lang="scss" src="./SalesHistory.scss"/>
